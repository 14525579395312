// eslint-disable-next-line no-unused-vars
import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../utils/motion";
import PropTypes from "prop-types";

const ServiceCard = (item) => {
  return (
    <motion.div
      variants={fadeIn("right", "spring", 0.5 * item.index, 0.75)}
      options={{ max: 45, scale: 1, speed: 450 }}
      className="xs:w-[250px] w-full card-gradient p-[1px] rounded-[20px] shadow-card"
    >
      <div className="bg-jetLight rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col">
        <img
          src={item.icon}
          alt={item.title}
          className="w-16 h-16 object-contain"
        />
        <h3 className="text-taupe text-[18px] font-bold text-center">
          {item.title}
        </h3>
      </div>
    </motion.div>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceCard;
