import {
  frontend,
  backend,
  ux,
  prototyping,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  git,
  figma,
  docker,
  postgresql,
  graphql,
  movie,
  nyeusi,
  sigma,
  flutter,
  latitudde,
  imuko,
  apiux,
  periferia,
  nexos,
  logo,
  quicksale,
  solinterapp,
  komikult,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "Acerca",
  },
  {
    id: "projects",
    title: "Proyectos",
  },
  {
    id: "contact",
    title: "Contacto",
  },
];

const services = [
  {
    id: "service-1",
    title: "Desarrollo Frontend",
    icon: frontend,
  },
  {
    id: "service-2",
    title: "Desarrollo Backend",
    icon: backend,
  },
  {
    id: "service-3",
    title: "UI/UX Design",
    icon: ux,
  },
  {
    id: "service-4",
    title: "Soluciones IOT",
    icon: prototyping,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "Flutter",
    icon: flutter,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "graphql",
    icon: graphql,
  },
  {
    name: "postgresql",
    icon: postgresql,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
  {
    name: "docker",
    icon: docker,
  },
];

const experiences = [
  {
    title: "Desarrollador Full stack - NodeJs - Angular",
    company_name: "Periferia IT Group",
    icon: periferia,
    iconBg: "#118047",
    date: "Aug 2021 - Feb 2022",
  },
  {
    title: "Desarrollador Low Code - OutSystems",
    company_name: "Latitudde LATAM",
    icon: latitudde,
    iconBg: "#ffffff",
    date: "Mar 2022 - May 2022",
  },
  {
    title: "Desarrollador Full Stack",
    company_name: "Sigma SAS",
    icon: sigma,
    iconBg: "#ffffff",
    date: "May 2022 - Oct 2022",
  },
  {
    title: "Desarrollador front-end",
    company_name: "Imuko",
    icon: imuko,
    iconBg: "#ffffff",
    date: "Sep 2022 - Present",
  },
  {
    title: "Desarrollador Low Code - OutSystems",
    company_name: "Apiux Technologies",
    icon: apiux,
    iconBg: "#ffffff",
    date: "Sep 2022 - Present",
  },
  {
    title: "Desarrollador Low Code - OutSystems",
    company_name: "Nexos Software",
    icon: nexos,
    iconBg: "#333333",
    date: "Sep 2022 - Present",
  },
  {
    title: "Desarrollador Full Stack",
    company_name: "Sigma SAS",
    icon: sigma,
    iconBg: "#ffffff",
    date: "Sep 2022 - Present",
  },
  {
    title: "Desarrollador Full Stack",
    company_name: "Vitam venture",
    icon: logo,
    iconBg: "#ffffff",
    date: "Sep 2022 - Present",
  },
];

const projects = [
  {
    id: "project-1",
    name: "QuickSale",
    description:
      "Diseño, desarrollo y lanzamiento de app móvil para la gestión de pedidos y domicilios de restaurantes.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
    ],
    image: quicksale,
    repo: "",
    demo: "",
  },
  {
    id: "project-2",
    name: "SolinterApp",
    description:
      "Diseño, desarrollo y lanzamiento de app móvil para la gestión y realización de visitas de la empresa Solinter S.A.S.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: solinterapp,
    repo: "",
    demo: "",
  },
  {
    id: "project-3",
    name: "Math Magicians",
    description: "This is a single-page calculator app built with React",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: komikult,
    repo: "",
    demo: "",
  },
  {
    id: "project-4",
    name: "Movie Metro",
    description: `A single-page application that allows users to search for any movie or show's ratings and its details.`,
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: movie,
    repo: "",
    demo: "",
  },
  {
    id: "project-5",
    name: "Nyeusi Fest Site",
    description:
      "This is a demo concert website for a music festival called Nyeusi.",
    tags: [
      {
        name: "nextjs",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: nyeusi,
    repo: "",
    demo: "",
  },
];

export { services, technologies, experiences, projects };
